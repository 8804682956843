//------------------------------------
//	# Spinner
// ## usage ##
// Add below data attributes to element that which triggered spinner
// and add class name as load-target that which has spinner effect
// <a data-load-target="targetElementClassName" data-action="ajax:beforeSend->helpers--xhr-loader#loader"></a>
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {

  loader(event){
    const targetElement = document.querySelector(event.target.dataset.loadTarget);
    if (targetElement) {
      let delay = setTimeout( () => {
        targetElement.classList.add('spinner');
      }, 300);
      event.target.addEventListener('ajax:complete', () => {
        clearTimeout(delay);
        targetElement.classList.remove('spinner');
      });
    }
  }
}
