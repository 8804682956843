//------------------------------------
//	# Order Forms - Card Form
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [
    'inputQuantityField',
    'productPrice',
    'productPaidPrice',
    'btnDecreaseQuantity',
    'paymentStep',
    'stepTitle',
    'addressStep',
    'step',
    'product',
    'form',
    'submit',
    'remove',
    'productCard',
    'creditCardForm',
    'initialHint',
    'optionsContainer'
  ];

  initialize() {
    this.checkOrderQuantityCookie();
    this.setQuantityCookie();
    this.initialProductPaidPrice = this.productPaidPriceTarget.innerHTML;
  }

  setQuantityCookie() {
    localStorage.setItem('quantity', this.inputQuantityFieldTarget.value) + 'path=/';
    document.cookie = 'order_quantity=' + this.inputQuantityFieldTarget.value;
    this.setPriceAndPaidPrice(localStorage.getItem('quantity'));
  }

  increaseQuantity() {
    this.inputQuantityFieldTarget.value = parseInt(this.inputQuantityFieldTarget.value) + 1;
    this.setQuantityCookie();
    if (parseInt(this.inputQuantityFieldTarget.value) > 1) {
      this.btnDecreaseQuantityTarget.removeAttribute('disabled');
    }
  }

  decreaseQuantity(event) {
    this.inputQuantityFieldTarget.value = parseInt(this.inputQuantityFieldTarget.value) - 1;
    this.setQuantityCookie();
    if (parseInt(this.inputQuantityFieldTarget.value) === 1) {
      event.currentTarget.setAttribute('disabled', true);
    }
  }

  setPriceAndPaidPrice(quantity) {
    const price = this.productPriceTarget.dataset.price * quantity;
    const paidPrice = this.productPaidPriceTarget.dataset.paidPrice * quantity;
    //this.productPriceTarget.textContent = "₺ " + price.toFixed(2);
    //this.productPaidPriceTarget.textContent = "₺ " + paidPrice.toFixed(2);
    localStorage.setItem('price', this.productPaidPriceTarget.textContent);
  }

  checkOrderQuantityCookie() {
    if (parseInt(this.inputQuantityFieldTarget.dataset.orderQuantity) === 1) {
      this.btnDecreaseQuantityTarget.setAttribute('disabled', true);
    } else {
      this.inputQuantityFieldTarget.value = this.inputQuantityFieldTarget.dataset.orderQuantity;
      this.btnDecreaseQuantityTarget.removeAttribute('disabled');
    }
  }

  step() {
    if (this.stepTarget.dataset.step === 'payment') {
      this.stepTitleTarget.textContent = "Ödeme Seçenekleri";
      if(this.stepTarget.dataset.mobileApp === 'false'){
        this.paymentStepTarget.classList.add('is-step-active');
        this.addressStepTarget.classList.remove('is-step-active');
      }
    } else {
      this.stepTitleTarget.textContent = "Sipariş Bilgilerinizi Giriniz";
      if(this.stepTarget.dataset.mobileApp === 'false'){
        this.addressStepTarget.classList.add('is-step-active');
        this.paymentStepTarget.classList.remove('is-step-active');
      }
    }
  }

  updateUrl() {
    history.replaceState({}, "", this.stepTarget.dataset.targetPath + '?payment_type=credit_card');
  }

  //
  // Coupon
  //

  apply_success(event){
    let [data, status, xhr] = event.detail;
    this.productCardTarget.innerHTML = xhr.response;
    this.setPriceAndPaidPrice(localStorage.getItem('quantity'));
    this.resetPaymentForm();
  }

  remove_coupon(event){
    let [data, status, xhr] = event.detail;
    this.productCardTarget.innerHTML = xhr.response;
    this.resetPaymentForm();
  }

  apply_failed(event){
    let [data, status, xhr] = event.detail;
    this.productCardTarget.innerHTML = xhr.response;
    this.setPriceAndPaidPrice(localStorage.getItem('quantity'));
  }

  changeForInstallment(e) {
    this.productPaidPriceTarget.innerHTML = e.detail.value || this.initialProductPaidPrice;
  }

  resetPaymentForm(){
    if(this.hasCreditCardFormTarget){
      this.creditCardFormTarget.reset();
      this.creditCardFormTarget.dataset.fetchBinNull = true;
      this.optionsContainerTarget.innerHTML = `<option value="1"> Kart numaranızı giriniz.</option>`;
      this.optionsContainerTarget.setAttribute('disabled', true);
      this.initialHintTarget.removeAttribute('hidden');
    }
  }
}
