//------------------------------------
//	# Calendar
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["calendarCell"];

  updateCalendarBody(event) {
    let [data, status, xhr] = event.detail;
    this.element.innerHTML = xhr.response;
  }

  highlightSelectedCell(event) {
    this.calendarCellTargets.forEach((element) => {
      element.classList.toggle("calendar-cell-active", element === event.target);
    });
  }
}
