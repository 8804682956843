//------------------------------------
//	# Video component
//------------------------------------

// https://github.com/sampotts/plyr

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['learnableId', 'lectureList', 'lectureSearchField', 'learnableSelectBox'];

  loadLearnableRecordings(event) {
    // learnable_type and inside_title values set
    if(event.target.id === 'lecture_learnable_type'){
      this.resetFields();

      var learnable_type = event.target.value;
      var inside_search_field = '';
    }else{
      // lecture_learnable_text
      var learnable_type = this.learnableSelectBoxTarget.value;
      var inside_search_field = encodeURI(event.target.value);
    }

    if(learnable_type) {
      Rails.ajax({
        url: event.target.dataset.url + `/?learnable_type=${learnable_type}&inside_title=${inside_search_field}`,
        type: 'GET',
        accept: 'html',
        success: (response, status, xhr) => {
          this.lectureListTarget.innerHTML = xhr.response;
        }
      });
    } else {
      this.resetFields();
      this.lectureListTarget.innerHTML = '';
    }
  }

  openLectureList(){
    this.loadLearnableRecordings(event);
    this.lectureListTarget.hidden = !this.lectureListTarget.hidden;
  }

  selectLectureItem(event){
    this.lectureListTarget.hidden = true;

    this.learnableIdTarget.value = event.currentTarget.value;
    this.lectureSearchFieldTarget.value = event.currentTarget.innerText;
  }

  resetFields(){
    this.learnableIdTarget.value = '';
    this.lectureListTarget.innerHTML = '';
    this.lectureListTarget.hidden = true;
    this.lectureSearchFieldTarget.value = '';
  }
}
