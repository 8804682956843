//------------------------------------
//	# Modal component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [
    'modal',
    'backdrop',
    'iframe'
  ];

  backdrop(condition) {
    const backdrop = document.createElement('div');
    if (condition) {
      this.element.appendChild(backdrop);
      backdrop.classList.add('modal-backdrop', 'fade', 'show');
      backdrop.setAttribute('data-target', 'modal.backdrop');
      backdrop.setAttribute('data-action', 'click->modal#close');
    } else {
      this.element.removeChild(this.backdropTarget);
    }
  }

  open(event) {
    if (this.modalTarget.dataset.modalType === 'bootstrap') {
      this.modalTargets.forEach(element => {
        if (event.currentTarget.dataset.modalId === element.id) {
          element.classList.add('d-block');
        }
      });
    } else {
      this.modalTargets.forEach(element => {
        if (event.currentTarget.dataset.modalId === element.id) {
          element.hidden = false;
        }
      });
    }

    document.body.classList.add('modal-open');
    this.backdrop(true);

    if (document.body.contains(document.querySelector('.js-quantity') || document.querySelector('.js-price'))) {
      document.querySelector('.js-quantity').textContent = localStorage.getItem('quantity');
      document.querySelector('.js-price').textContent = localStorage.getItem('price');
    }

  }

  close() {
    if (this.modalTarget.dataset.modalType === 'bootstrap') {
      this.modalTargets.forEach(element => {
        if (element.classList.contains('d-block')) {
          element.classList.remove('d-block');
        }
      });
    } else {
      this.modalTargets.forEach(element => {
        element.hidden = true;
      });
    }
    document.body.classList.remove('modal-open');
    this.backdrop(false);
  }

  disconnect() {
    localStorage.removeItem('quantity');
    localStorage.removeItem('price');
  }

  setVideoSrc(event){
    if(event.currentTarget.hasAttribute('data-modal-src')){
      this.iframeTarget.src =  event.currentTarget.dataset.modalSrc;
    }
  }

  clearVideoSrc(){
    this.iframeTarget.src = '';
  }
}
