//------------------------------------
//	# XHR Form component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

  onAjaxError(event) {
    
    let [data, status, xhr] = event.detail;
      this.element.innerHTML = xhr.response;
    // ReCaptcha
    // Registration
    var element = document.getElementsByClassName('g-recaptcha')[0];
    if (window.grecaptcha && element) {
      window.grecaptcha.render(element);
    }
    
   /*
    let [data, status, xhr] = event.detail;
    const doc = new DOMParser().parseFromString(xhr.response, 'text/html');
    if(this.element.querySelector('#error_explanation') == null) 
      this.element.insertBefore(doc.querySelector('#error_explanation'), this.element.children[1])
    else
      this.element.replaceChild(doc.querySelector('#error_explanation'), this.element.children[1])
  */
  }
}
