//------------------------------------
//	# Broadcast
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ["list"];

  updateBroadcastList(event) {
    let [data, status, xhr] = event.detail;
    this.listTarget.innerHTML = xhr.response;
  }

  reload() {
    Rails.ajax({
      url: this.data.get("url"),
      type: "GET",
      accept: 'html',
      success: (document, status, response) => {        
        this.listTarget.innerHTML = response.responseText;
      }
    });
  }
}
