//------------------------------------
//	# Answer Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['area', 'checkbox','label'];

  initialize() {
    this.checkboxTargets.forEach(element => {
      if (element.checked) {
        this.labelTarget.classList.add('active');
      }
    });
  }

  toggleOpacity() {
    this.areaTargets.forEach(element => {
      if (element.classList.contains('opacity')) {
        element.classList.remove('opacity');
      } else {
        element.classList.add('opacity');
      }
    });
  }

  toggleCorrect(event) {
    if (event.target.checked) {
      event.currentTarget.classList.add('active');
    } else {
      event.currentTarget.classList.remove('active');
    }
  }


}
