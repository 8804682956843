//------------------------------------
//	# Video component
//------------------------------------

// https://github.com/sampotts/plyr

// jshint esversion: 6

import { Controller } from "stimulus";
import Plyr from "plyr";

export default class extends Controller {
  initialize() {
    const platform = this.data.get("platform");

    // Hide iframe until Plyr is ready.
    this.element.style.opacity = "0";

    // Setup options
    const options = [
      "play-large",
      "play",
      "progress",
      "current-time",
      "captions",
      "settings",
    ];
    if (platform === "android") {
      options.push("fullscreen");
    } else if (platform !== "ios") {
      options.push("mute", "volume", "fullscreen");
    }

    // Initialize Plyr
    const player = new Plyr(this.element, {
      controls: ["rewind", ...options, "fast-forward"],
      quality: {
        default:
          platform === "mobile_browser" ||
          platform === "ios" ||
          platform === "android"
            ? 360
            : 720,
        options: [720, 360],
      },
    });

    player.once("ready", () => {
      this.element.classList.add("fade-in");
    });

    if (platform === "android") {
      player.on("enterfullscreen", () => {
        AndroidBridge.enableFullScreen("");
      });
      player.on("exitfullscreen", () => {
        AndroidBridge.disableFullScreen("");
      });
    }

    this.trackVideoEvent(player);
  }

  trackVideoEvent(player) {
    const element = document.getElementById("detailLecture");
    if (
      element.getAttribute("data-video-track-event-available") == "true" &&
      element.getAttribute("data-video-track-event-lecture-type") == "Video"
    ) {
      let startProgress = true;
      player.on("play", (event) => {
        if (startProgress) {
          startProgress = false;
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            event: "GAEvent", //Sabit
            event_name: "video_play", //Sabit
            eventCategory: "Video", //Sabit
            eventAction: "Start", //Sabit
            eventLabel: element.getAttribute("data-video-track-event-label"), //Video ismi gönderilmelidir.
            breadcrumb: element.getAttribute(
              "data-video-track-event-video-name-breadcrumb"
            ), //Video ismi breadcrumb beraberinde gönderilmelidir.
            videoCategory: element.getAttribute(
              "data-video-track-event-video-category"
            ), //Video kategorisi gönderilmelidir.
            section: element.getAttribute(
              "data-video-track-event-video-section"
            ), //Video bölümü gönderilmelidir.
            step: element.getAttribute("data-video-track-event-video-step"), //Video adımı gönderilmelidir.
          });
        }
      });

      player.on("ended", (event) => {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "GAEvent", //Sabit
          event_name: "video_100_play", //Sabit
          eventCategory: "Video", //Sabit
          eventAction: "%100 Play", //Sabit
          eventLabel: element.getAttribute("data-video-track-event-label"), //Video ismi gönderilmelidir.
          breadcrumb: element.getAttribute(
            "data-video-track-event-video-name-breadcrumb"
          ), //Video ismi breadcrumb beraberinde gönderilmelidir.
          videoCategory: element.getAttribute(
            "data-video-track-event-video-category"
          ), //Video kategorisi gönderilmelidir.
          section: element.getAttribute("data-video-track-event-video-section"), //Video bölümü gönderilmelidir.
          step: element.getAttribute("data-video-track-event-video-step"), //Video adımı gönderilmelidir.
        });
      });

      let firstProgress = true;
      let secondProgress = true;
      let thirdProgress = true;

      player.on("progress", (event) => {
        const duration = player.duration;
        const currentTime = player.currentTime;
        localStorage.setItem(
          `${element.getAttribute(
            "data-video-track-event-video-step"
          )}currentTime`,
          currentTime
        );
        let x = (100 * currentTime) / duration / 100;

        if (0.25 <= x && x < 0.5) {
          if (firstProgress) {
            firstProgress = false;
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              event: "GAEvent", //Sabit
              event_name: "video_25_play", //Sabit
              eventCategory: "Video", //Sabit
              eventAction: "%25 Play", //Sabit
              eventLabel: element.getAttribute("data-video-track-event-label"), //Video ismi gönderilmelidir.
              breadcrumb: element.getAttribute(
                "data-video-track-event-video-name-breadcrumb"
              ), //Video ismi breadcrumb beraberinde gönderilmelidir.
              videoCategory: element.getAttribute(
                "data-video-track-event-video-category"
              ), //Video kategorisi gönderilmelidir.
              section: element.getAttribute(
                "data-video-track-event-video-section"
              ), //Video bölümü gönderilmelidir.
              step: element.getAttribute("data-video-track-event-video-step"), //Video adımı gönderilmelidir.
            });
          }
        } else if (0.5 <= x && x < 0.75) {
          if (secondProgress) {
            secondProgress = false;
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              event: "GAEvent", //Sabit
              event_name: "video_50_play", //Sabit
              eventCategory: "Video", //Sabit
              eventAction: "%50 Play", //Sabit
              eventLabel: element.getAttribute("data-video-track-event-label"), //Video ismi gönderilmelidir.
              breadcrumb: element.getAttribute(
                "data-video-track-event-video-name-breadcrumb"
              ), //Video ismi breadcrumb beraberinde gönderilmelidir.
              videoCategory: element.getAttribute(
                "data-video-track-event-video-category"
              ), //Video kategorisi gönderilmelidir.
              section: element.getAttribute(
                "data-video-track-event-video-section"
              ), //Video bölümü gönderilmelidir.
              step: element.getAttribute("data-video-track-event-video-step"), //Video adımı gönderilmelidir.
            });
          }
        } else if (0.75 <= x && x < 1) {
          if (thirdProgress) {
            thirdProgress = false;
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              event: "GAEvent", //Sabit
              event_name: "video_75_play", //Sabit
              eventCategory: "Video", //Sabit
              eventAction: "%75 Play", //Sabit
              eventLabel: element.getAttribute("data-video-track-event-label"), //Video ismi gönderilmelidir.
              breadcrumb: element.getAttribute(
                "data-video-track-event-video-name-breadcrumb"
              ), //Video ismi breadcrumb beraberinde gönderilmelidir.
              videoCategory: element.getAttribute(
                "data-video-track-event-video-category"
              ), //Video kategorisi gönderilmelidir.
              section: element.getAttribute(
                "data-video-track-event-video-section"
              ), //Video bölümü gönderilmelidir.
              step: element.getAttribute("data-video-track-event-video-step"), //Video adımı gönderilmelidir.
            });
          }
        }
      });
    }
  }

  disconnect() {
    const element = document.getElementById("detailLecture");
    if (
      element.getAttribute("data-video-track-event-available") == "true" &&
      element.getAttribute("data-video-track-event-lecture-type") == "Video"
    ) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "GAEvent", //Sabit
        event_name: "video_izleme_suresi", //Sabit
        eventCategory: "Video", //Sabit
        eventAction: "İzleme Süresi", //Sabit
        eventLabel: element.getAttribute("data-video-track-event-label"), //Video ismi gönderilmelidir.
        eventValue: localStorage.getItem(
          `${element.getAttribute(
            "data-video-track-event-video-step"
          )}currentTime`
        ), //Video izleme süresi saniye cinsinden integer olarak gönderilmelidir.
        breadcrumb: element.getAttribute(
          "data-video-track-event-video-name-breadcrumb"
        ), //Video ismi breadcrumb beraberinde gönderilmelidir.
        videoCategory: element.getAttribute(
          "data-video-track-event-video-category"
        ), //Video kategorisi gönderilmelidir.
        section: element.getAttribute("data-video-track-event-video-section"), //Video bölümü gönderilmelidir.
        step: element.getAttribute("data-video-track-event-video-step"), //Video adımı gönderilmelidir.
      });
      localStorage.removeItem(
        `${element.getAttribute(
          "data-video-track-event-video-step"
        )}currentTime`
      );
    }
  }
}
