//------------------------------------
//	# Mobile alert component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['layout'];

  connect() {
    if (this.layoutTarget.dataset.deviceType === 'ios') {
      this.layoutTarget.dataset.messageType === 'alert' ? webkit.messageHandlers.alertMessage.postMessage(this.layoutTarget.textContent) : webkit.messageHandlers.noticeMessage.postMessage(this.layoutTarget.textContent);
    } else {
      AndroidBridge.alertMessage(this.layoutTarget.textContent);
    }
  }

}

