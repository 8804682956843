//------------------------------------
//	# Dashboard
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

    static targets = ["dashboardSelectBox"];

    setDashboardList() {
        Turbolinks.visit('/user/' + `?selected_option=${this.dashboardSelectBoxTarget.value}`, { "action":"replace" });
    }
}