//------------------------------------
//	# Calendar Loader
//------------------------------------

// jshint esversion: 6

// ! For now it's didn't use in the page

import { Controller } from "stimulus";

export default class extends Controller {
  
  static targets = []

  connect() {
    this.load();
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html;
      });
  }
}
