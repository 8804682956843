//------------------------------------
//	# Coupon
// ------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ['token', 'apply', 'remove', 'productCard'];

    hrefUpdate(event){
        event.currentTarget.href = event.currentTarget.href + '?token=' + this.tokenTarget.value;
    }

    applyCoupon(event){
        let [response, status, xhr] = event.detail;
        this.productCardTarget.innerHTML = xhr.response;
    }
}