//------------------------------------
//	# Countdown
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["days", "hours", "minutes", "seconds"];

    connect() {
        const finishedAt = this.element.dataset.finishedAt;
        this.startTimer(finishedAt)

    }

    startTimer(finishedAt){
        // SetInterval

        const countdown = setInterval( function() {
            const today = new Date(Date.now());
            const distance = finishedAt - today;
            if(distance <= 0){
                document.getElementById("days").innerHTML = '00';
                document.getElementById("hours").innerHTML = '00';
                document.getElementById("minutes").innerHTML = '00';
                document.getElementById("seconds").innerHTML = '00';
                stopTimer();
            }else{
                const timerDays = setNumber(Math.floor(distance / (1000 * 60 * 60 * 24)));
                const timerHours = setNumber(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                const timerMinutes = setNumber(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
                const timerSeconds = setNumber(Math.floor((distance % (1000 * 60)) / 1000));


                if(!document.getElementById("seconds"))
                    return;
                document.getElementById("days").innerHTML = `${timerDays}`;
                document.getElementById("hours").innerHTML = `${timerHours}`;
                document.getElementById("minutes").innerHTML = `${timerMinutes}`;
                document.getElementById("seconds").innerHTML = `${timerSeconds}`;
            }

            function setNumber(number) {
                if(number.toString().length === 1){
                    return '0' + number.toString();
                }else{
                    return number.toString();
                }
            }
            function stopTimer () {
                clearInterval(countdown);
            }
        }, 100);
    }
}
