import { Controller } from "stimulus";

export default class extends Controller {
  update(event) {
    const option = Array.from(event.target.list.options).find(
      (opt) => opt.value === event.target.value
    );
    if (option) {
      document.getElementById("product_id").value = option.dataset.value;
    } else {
      document.getElementById("product_id").value = "";
    }
  }

  validate(event) {
    const option = Array.from(event.target.list.options).find(
      (opt) => opt.value === event.target.value
    );
    if (!option) {
      event.target.value = "Seçilmedi";
    }
  }
}
