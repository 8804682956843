//------------------------------------
//	# Register Form
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = []

  connect() {
    console.log('user/registration_form_controller');
  }

}
