//------------------------------------
//	# Datetime Picker Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import { Datepicker } from 'vanillajs-datepicker';
import tr from '../../../../node_modules/vanillajs-datepicker/js/i18n/locales/tr.js';

export default class extends Controller {
  static targets = ["createdAtGtEq", "createdAtLtEq",
                    "expiredAtGtEq", "expiredAtLtEq", "expiredAt",
                    "expireDateGtEq", "expireDateLtEq"];

  initialize() {
    Object.assign(Datepicker.locales, tr);
    if(this.targets.element.id === 'course_access_search' || this.targets.element.id === 'order_search' || this.targets.element.id === 'user_search'){
      new Datepicker(this.createdAtGtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
      new Datepicker(this.createdAtLtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
    }

    if(this.targets.element.id === 'course_access_search'){
      new Datepicker(this.expiredAtGtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
      new Datepicker(this.expiredAtLtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
    }

    if(this.targets.element.id === 'course_access_form'){
      new Datepicker(this.expiredAtTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
    }
    console.log('yeh')

    if(this.targets.element.id === 'coupon_search'){
      new Datepicker(this.expireDateGtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
      new Datepicker(this.expireDateLtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
      new Datepicker(this.createdAtGtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
      new Datepicker(this.createdAtLtEqTarget, {
        buttonClass: 'btn',
        language: 'tr'
      });
    }
  }
}
