//------------------------------------
//	# Billing/Shipping Form
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    'billingForm',
    'shippingForm',
    'billingAddresses',
    'shippingAddresses',
    'billingAddressId',
    'shippingAddressId',
    'selectCity',
    'selectTown',
    'shippingButton',
    'selectedAddress',
    'errorMessagesText',
    'checkboxShippingAndBillingAddressSame',
    'billingButton']

  href;
  remote;
  initialize() {
    this.href = this.shippingButtonTarget.getAttribute('href');
    this.remote = this.shippingButtonTarget.getAttribute('data-remote');
    this.isCheckboxShippingAndBillingAddressSame();
    this.checkboxShippingAndBillingAddressSameTarget.addEventListener('checked', () => {
      setTimeout(() => {
        this.checkboxShippingAndBillingAddressSameTarget.checked = true;
        this.displayShippingAddresses(true);
      }, 200);
    }, false);
  }

  setShippingAddressIdBillingAddressIdInfos() {
    let isShippingBillingAddressSame = false;
    let orderBillingAddressId, orderShippingAddressId;
    document.querySelectorAll('input:checked').forEach((element) => {
      switch (element.name) {
        case 'address[billing]':
          orderBillingAddressId = element.value;
          break;
        case 'address[shipping]':
          orderShippingAddressId = element.value;
          break;
        case 'shipping-and-billing-address-same':
          isShippingBillingAddressSame = true;
          break;
      }
    });
    if (isShippingBillingAddressSame) {
      this.billingAddressIdTarget.value = orderBillingAddressId;
      this.shippingAddressIdTarget.value = orderBillingAddressId;
      if(orderBillingAddressId)
        document.getElementById('address_shipping_' + this.billingAddressIdTarget.value).checked = true;
    } else {
      this.billingAddressIdTarget.value = orderBillingAddressId;
      this.shippingAddressIdTarget.value = orderShippingAddressId;
    }
  }

  isCheckboxShippingAndBillingAddressSame() {
    this.setShippingAddressIdBillingAddressIdInfos();
    if (this.checkboxShippingAndBillingAddressSameTarget.checked) {
      this.shippingAddressesTarget.hidden = true;
      this.shippingButtonTarget.classList.add('disabled');
      this.shippingButtonTarget.removeAttribute('href');
      this.shippingButtonTarget.removeAttribute('data-remote');
      this.cancelShippingAddressForm();
    } else {
      this.shippingButtonTarget.setAttribute('href', this.href);
      this.shippingButtonTarget.setAttribute('data-remote', this.remote);
      this.shippingButtonTarget.classList.remove('disabled');
    }
  }

  setAddressForm(event) {
    let [data, status, xhr] = event.detail;
    let address_form = (data.all['address_form_type'].value === 'billing') ? this.billingFormTarget : this.shippingFormTarget;
    address_form.innerHTML = xhr.response;
    this.updateField();
  }

  cancelBillingAddressForm() {
    this.billingFormTarget.innerHTML = '';
    this.enableBtn();
  }

  cancelShippingAddressForm() {
    this.shippingFormTarget.innerHTML = '';
    this.enableBtn();
  }

  changeShippingAndBillingAddressSame(event) {
    this.displayShippingAddresses(event.currentTarget.checked);
  }

  setOrderAddress(event) {
    let selectedAddress = event.target;
    let addressInfoId = (selectedAddress.name === 'address[billing]' ? this.billingAddressIdTarget : this.shippingAddressIdTarget);
    addressInfoId.value = selectedAddress.dataset.addressId;
    this.setShippingAddressIdBillingAddressIdInfos();
  }

  errorMessages(event) {
    let [data, status, xhr] = event.detail;
    this.errorMessagesTextTarget.innerHTML = xhr.response;
  }

  editShippingAddressInCreate() {
    if (document.contains(document.getElementById('error_explanation'))) {
      document.getElementById('error_explanation').remove();
    }
    this.checkboxShippingAndBillingAddressSameTarget.disabled = false;
    this.checkboxShippingAndBillingAddressSameTarget.checked = false;
    this.shippingAddressesTarget.hidden = false;
    this.billingAddressesTarget.hidden = false;
  }

  //
  // Private methods
  //

  displayShippingAddresses(hideShippingAddresses) {
    this.shippingAddressesTarget.hidden = hideShippingAddresses;
    this.isCheckboxShippingAndBillingAddressSame();
  }

  //
  // City - Town
  //

  updateField(){
    Rails.ajax({
      url: this.selectCityTarget.dataset.url + `/?${this.selectCityTarget.dataset.name}=${this.selectCityTarget.value}`,
      type: 'GET',
      accept: 'json',
      success: (response, status, xhr) => {
        let options = '<option value selected></option>';
        if(response && response.length > 0) {
          response.forEach(town=>{
            options+= `<option value="${town}">${town}</option>`;
          });
        }
        this.selectTownTarget.innerHTML = options;

        // For Update
        if(this.selectTownTarget.querySelector('[value="' + this.selectTownTarget.dataset.selected + '"]')){
          this.selectCityTarget.querySelector('[value="' + this.selectCityTarget.dataset.selected + '"]').selected = true;
          this.selectTownTarget.querySelector('[value="' + this.selectTownTarget.dataset.selected + '"]').selected = true;
        }
      }
    });
  }
  disableBtn(){
    if(this.billingButtonTarget.style.pointerEvents != 'none'){
      this.billingButtonTarget.style.pointerEvents = 'none';
      this.billingButtonTarget.style.backgroundColor = '#e9ecef';
    }
    if(this.shippingButtonTarget.style.pointerEvents != 'none'){
      this.shippingButtonTarget.style.pointerEvents = 'none';
      this.shippingButtonTarget.style.backgroundColor = '#e9ecef';
    }
  }
  enableBtn(){
    if(this.billingButtonTarget.style.pointerEvents != 'auto'){
      this.billingButtonTarget.style.pointerEvents = 'auto';
      this.billingButtonTarget.style.backgroundColor = '#6A6969';
    }
    if(this.shippingButtonTarget.style.pointerEvents != 'auto'){
      this.shippingButtonTarget.style.pointerEvents = 'auto';
      this.shippingButtonTarget.style.backgroundColor = '#6A6969';
    }
  }
}
