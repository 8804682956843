//------------------------------------
//	# Carousel component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['image', 'thumbnail'];

  slide(event) {
    this.thumbnailTargets.forEach(element => {
      if (element.classList.contains('border-warning')) {
        element.classList.remove('border-warning');
      }
    });
    event.currentTarget.classList.add('border-warning');
    this.imageTargets.forEach(element => {
      if (element.classList.contains('d-block')) {
        element.classList.remove('d-block');
        element.classList.add('d-none');
      }
      if (event.currentTarget.id === element.dataset.imageId) {
        element.classList.add('d-block');
      }
    });
  }

}

