//------------------------------------
//	# Exams Form
//------------------------------------

import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { $, $$ } from "../../lib/selector";

export default class extends Controller {
  static targets = [
    "video",
    "formWrapper",
    "form",
    "restartButton",
    "answer",
    "score",
    "correctAnswerIds",
    "examQuestionsCount",
  ];

  connect() {
    this.examQuestionsCountTarget.value = $$(
      "form[data-is-multiple=false]"
    ).length;
  }

  sendUserAnswers() {
    window.scrollTo(0, 0);
    this.videoTarget.hidden = false;
    this.restartButtonTarget.hidden = false;
    this.correctAnswerIdsTarget.value = "";
    this.formTargets.forEach((el) => {
      Rails.fire(el, "submit");
    });
  }

  displayQuestionVideo(event) {
    let exam_question_id = event.currentTarget.dataset.examQuestion;
    let exam_question_video = document.getElementById(exam_question_id);
    exam_question_video.hidden = !exam_question_video.hidden;
  }

  showResult(event) {
    let [data, status, xhr] = event.detail;
    event.target.innerHTML = xhr.response;
    const selectCorrectAnswers = event.target.querySelectorAll(
      "label[data-is-correct=true]:not([data-multiple=true])"
    );

    selectCorrectAnswers.forEach((element) => {
      if (this.correctAnswerIdsTarget.value === "") {
        this.correctAnswerIdsTarget.value = element.dataset.value;
      } else {
        this.correctAnswerIdsTarget.value += "," + element.dataset.value;
      }
    });

    const correctAnswersArr = this.correctAnswerIdsTarget.value.split(",");
    let score = 0;
    correctAnswersArr.forEach((element) => {
      if (element !== "") {
        score = Math.round(
          (correctAnswersArr.length * 100) / this.examQuestionsCountTarget.value
        );
      }
    });

    this.scoreTarget.innerText = score;
    $("input[name='progress[score]']").value = score;
    $(
      "input[name='progress[answer_ids]']"
    ).value = this.correctAnswerIdsTarget.value;
  }
}
