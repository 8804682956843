//------------------------------------
//	# Address Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["listAddress", "formAddress", "formActionChange", "addressSelectBox", "userId"];

    listRender(event){
        // add or remove
        let [data, status, xhr] = event.detail;
        this.listAddressTarget.innerHTML = xhr.responseText;
        this.getForm();
    }

    formRender(event){
        // error or update
        let [data, status, xhr] = event.detail;
        this.formAddressTarget.innerHTML = xhr.responseText;
    }

    getForm(){
        Rails.ajax({
            url: '/admin/addresses/new',
            type: 'GET',
            data: 'address[user_id]=' + this.userIdTarget.value,
            accept: 'html',
            success: (response, status, xhr) => {
                this.formAddressTarget.innerHTML = xhr.responseText;
            }
        });
    }
}
