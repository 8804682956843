//------------------------------------
//	#
//------------------------------------

import { Controller } from "stimulus";
import Swiper, { Navigation } from "swiper";

export default class extends Controller {
  static targets = ["next", "prev"];
  initialize() {
    Swiper.use(Navigation);
    new Swiper(this.element, {
      slidesPerView: 4,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
    });
  }
}
