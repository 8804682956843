//------------------------------------
//	# Navbar Action
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ['menuList']

    connect(){
        this.menuListTarget.hidden = true;
    }

    menu() {
        this.menuListTarget.hidden = !this.menuListTarget.hidden;
    }
}
