//------------------------------------
//	# Order
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ 'email', 'fullName', 'mobilePhone',
        'detailedAddressForBilling', 'cityForBilling',
        'townForBilling', 'totalPrice', 'netPaidPrice',
        'detailedAddressForShipping', 'cityForShipping', 'townForShipping',
        'billingAddressDialog', 'displayBillingAddress',
        'displayShippingAddress', 'addAddressButton', 'editAddressButton']

    connect(){
        this.updateTotalPrice();
    }

    updateEmail(event){
        this.setInnerHTML(event, this.emailTargets)
    }

    updateFullName(event){
        this.setInnerHTML(event, this.fullNameTargets)
    }

    updateMobilePhone(event){
        this.setInnerHTML(event, this.mobilePhoneTargets)
    }

    updateDetailedAddressForBilling(event){
        this.setInnerHTML(event, this.detailedAddressForBillingTargets)
    }

    updateCityForBilling(event){
        this.setInnerHTML(event, this.cityForBillingTargets)
    }

    updateTownForBilling(event){
        this.setInnerHTML(event, this.townForBillingTargets)
    }

    updateDetailedAddressForShipping(event){
        this.setInnerHTML(event, this.detailedAddressForShippingTargets)
    }

    updateCityForShipping(event){
        this.setInnerHTML(event, this.cityForShippingTargets)
    }

    updateTownForShipping(event){
        this.setInnerHTML(event, this.townForShippingTargets)
    }

    updateTotalPrice(){
        this.totalPriceTarget.innerHTML =  '₺ '+ this.netPaidPriceTarget.dataset.paidPrice;
    }

    setInnerHTML(event, targets){
        targets.forEach(function(target) {
            target.innerHTML = `${event.currentTarget.value}`
        });
    }

    showAddressDialog(){
        this.billingAddressDialogTarget.showModal();
    }
    closeAddressDialog(){
        this.billingAddressDialogTarget.close();
    }
    saveAddressDialog(){
        const billingAddress = document.querySelector('#address_detailed_address').value + ' ' + 
        document.querySelector('#address_town').value + '/' + 
        document.querySelector('#address_city').value;
        const identity_number = document.querySelector('#address_identity_number').value;

        if(billingAddress.length > 2 && (identity_number.length == 11 && (/^[0-9]+$/).test(identity_number))){
            this.closeAddressDialog();
            this.displayBillingAddressTarget.children[2].innerHTML = identity_number;
            this.displayBillingAddressTarget.children[5].innerHTML = billingAddress;
            this.addAddressButtonTarget.classList.add('d-none');
            this.displayBillingAddressTarget.hidden = false;
            this.editAddressButtonTarget.hidden = false;
        }
        if(document.querySelector('#shipping_address_same') != null){
            const shippingAddress = document.querySelector('#shipping_address_detailed_address').value + ' ' +
            document.querySelector('#shipping_address_town').value + '/' +
            document.querySelector('#shipping_address_city').value;
            if(document.querySelector('#shipping_address_same').checked)
                this.displayShippingAddressTarget.children[2].innerHTML = billingAddress;
            else
                this.displayShippingAddressTarget.children[2].innerHTML = shippingAddress;
            this.displayShippingAddressTarget.hidden = false;
        }
    }
}