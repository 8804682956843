//------------------------------------
//	# Mask
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Inputmask from 'inputmask';

export default class extends Controller {

  static targets = ['phoneNumber', 'identityNumber']

  initialize() {
    this.phoneNumberMask();
    this.identityNumberMask();
  }

  phoneNumberMask(){
    Inputmask({ 'mask': '0999 999 9999' }).mask(this.phoneNumberTarget);
  }

  identityNumberMask(){
    Inputmask({ 'mask': '99999999999' }).mask(this.identityNumberTarget);
  }
}
