//------------------------------------
//	# Notification Component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  toggle(event) {
    Rails.ajax({
      url: event.target.getAttribute('data-path'),
      type: 'put'
    });
  }

  contactSupport(event) {
    event.preventDefault();
    if (event.target.dataset.platform == 'ios') {
      webkit.messageHandlers.showMailComposer.postMessage('Notification Trouble!');
    } else if (event.target.dataset.platform == 'android') {
      AndroidBridge.showMailComposer('Notification Trouble!');
    }
  }

}
