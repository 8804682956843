//------------------------------------
//	# Installment Info
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['optionsContainer', 'initialHint', 'inputCardNumber', 'creditCardForm'];

  initialize() {
    this.initialOption = this.optionsContainerTarget.innerHTML;
    if(this.data.get('pre-selection') !== '') {
      this.fetchOptionsFor(this.inputCardNumberTarget.value.replace(/_/g, '').replace(/\s+/g,''));
    }
  }

  fetchInstallmentInfo(event) {
    let binNumber = event.target.inputmask.unmaskedvalue();
    this.fetchOptionsFor(binNumber);
  }

  fetchOptionsFor(binNumber) {
    if(this.creditCardFormTarget.dataset.fetchBinNull === 'true')
      this.fetchedBin = null;

    if(binNumber.length > 5) {
      if(this.fetchedBin === binNumber.slice(0, 6)) {
        return;
      } else {
        this.fetchedBin = binNumber.slice(0, 6);
      }
      Rails.ajax({
        url: this.data.get('path') + `&price=${document.getElementById('paidPriceText').dataset.paidPrice}`,
        type: 'POST',
        data: `bin_number=${binNumber.slice(0, 6)}`,
        accept: 'html',
        success: (response, status, xhr) => {
          this.optionsContainerTarget.innerHTML = xhr.response;
          if(this.data.get('pre-selection') !== '') {
            this.optionsContainerTarget.value = this.data.get('pre-selection');
            this.data.set('pre-selection', '');
          }
          this.optionsContainerTarget.removeAttribute('disabled');
          this.initialHintTarget.setAttribute('hidden', true);
        },
        error: (error) => {
          console.error(error);
        }
      });
    } else {
      this.optionsContainerTarget.setAttribute('disabled', true);
      this.initialHintTarget.removeAttribute('hidden');
      this.optionsContainerTarget.innerHTML = this.initialOption;
      this.fetchedBin = null;
      this.sendInstallmentChangeEvent();
    }
  }

  onInstallmentOptionChange(e) {
    let value = e.target.options[e.target.selectedIndex].text;
    this.sendInstallmentChangeEvent(value)
  }

  sendInstallmentChangeEvent(value) {
    const event = new CustomEvent('installment-change', {
      detail: {
        value
      }
    });
    window.dispatchEvent(event);
  }
}
