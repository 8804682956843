//------------------------------------
//	# Course Access Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["listCourseAccess", "formCourseAccess", "formActionChange", "courseSelectBox", "userId"];

    listRender(event){
        // add or remove
        let [data, status, xhr] = event.detail;
        this.listCourseAccessTarget.innerHTML = xhr.responseText;
        this.getForm();
    }

    formRender(event){
        // error or update
        let [data, status, xhr] = event.detail;
        this.formCourseAccessTarget.innerHTML = xhr.responseText;
    }

    courseIdChange(event){
        if(this.formActionChangeTarget.dataset.persisted === 'true'){
            this.formActionChangeTarget.action =  '/admin/courses/' + event.currentTarget.value + '/course_accesses/' + this.formActionChangeTarget.action.split('/').pop();
        }else{
            this.formActionChangeTarget.action =  '/admin/courses/' + event.currentTarget.value + '/course_accesses';
        }
    }

    getForm(){
        Rails.ajax({
            url: '/admin/courses/' + this.courseSelectBoxTarget[0].value + '/course_accesses/new',
            type: 'GET',
            data: 'user_id=' + this.userIdTarget.value,
            accept: 'html',
            success: (response, status, xhr) => {
                this.formCourseAccessTarget.innerHTML = xhr.responseText;
            }
        });
    }
}
