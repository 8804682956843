//------------------------------------
//	# Billing/Shipping Form
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ['selectCity', 'selectTown', 'selectCityForShipping', 'selectTownForShipping', 'shippingForm', 'detailedAddress']

  //
  // City - Town
  //

  setTownField(){
    Rails.ajax({
      url: this.selectCityTarget.dataset.url + `/?${this.selectCityTarget.dataset.name}=${this.selectCityTarget.value}`,
      type: 'GET',
      accept: 'json',
      success: (response, status, xhr) => {
        let options = '<option value selected></option>';
        if(response && response.length > 0) {
          response.forEach(town=>{
            options+= `<option value="${town}">${town}</option>`;
          });
        }
        this.selectTownTarget.innerHTML = options;
      }
    });
  }

  setTownFieldForShipping(){
    Rails.ajax({
      url: this.selectCityForShippingTarget.dataset.url + `/?${this.selectCityForShippingTarget.dataset.name}=${this.selectCityForShippingTarget.value}`,
      type: 'GET',
      accept: 'json',
      success: (response, status, xhr) => {
        let options = '<option value selected></option>';
        if(response && response.length > 0) {
          response.forEach(town=>{
            options+= `<option value="${town}">${town}</option>`;
          });
        }
        this.selectTownForShippingTarget.innerHTML = options;
      }
    });
  }

  displayShippingForm(event){
    this.shippingFormTarget.hidden = event.currentTarget.checked;
    if(!event.currentTarget.checked){
      this.selectCityForShippingTarget.value = '';
      this.selectTownForShippingTarget.value = '';
      this.detailedAddressForShippingTarget.value = '';
    }
  }
}
