//------------------------------------
//	# Answers Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fields"];

  connect() {
    if (this.element.querySelector("[type='number']") &&
      this.element.querySelector("[type='number']").value === '')
      this.element.querySelector("[type='number']").value = '10';
  }

  toggleOpacity(e) {
    if (e.target.closest("[data-target='admin--answers.fields']").style.opacity === '0.5')
      e.target.closest("[data-target='admin--answers.fields']").style.opacity = '1';
    else e.target.closest("[data-target='admin--answers.fields']").style.opacity = '0.5';
  }

  add(e) {
    e.preventDefault();
    let form = e.target.dataset.fields;
    let location_value = (this.element.querySelectorAll("[type='number']").length + 1) * 10;
    form = form.replace(/type="number"/g, `type="number" value="${location_value}"`);
    e.target.insertAdjacentHTML('beforebegin',
      form.replace(/new_field/g, new Date().getTime().toString())
    );
  }
}
