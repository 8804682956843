//------------------------------------
//	# Payment
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Inputmask from 'inputmask';
import detect from '../lib/detect';
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    'cardCvc',
    'cardNumber',
    'cardExpDate',
    'cardHolderName',
    'cardType',
    'inputCvc',
    'inputCardNumber',
    'inputCardHolderName',
    'inputCardExpDate',
    'virtualCardWrapper',
    'button',
    'paymentForm'];

  initialize() {
    if(this.hasCardNumberTarget && this.hasCardHolderNameTarget &&
        this.hasCardExpDateTarget && this.hasCardCvcTarget){
      this.initializeCard();
      this.cardNumber = this.cardNumberTarget.textContent;
      this.cardHolderName = this.cardHolderNameTarget.textContent;
      this.cardExpDate = this.cardExpDateTarget.textContent;
      this.cardCvc = this.cardCvcTarget.textContent;
      Inputmask({ 'mask': '9999 9999 9999 9999' }).mask(this.inputCardNumberTarget);
      Inputmask({ alias: 'datetime', inputFormat: 'mm/yy', placeholder: 'aa/yy' }).mask(this.inputCardExpDateTarget);
      Inputmask({ 'mask': '999' }).mask(this.inputCvcTarget);
    }
  }

  setTargetValue(target, input) {
    if (input.value !== '') target.textContent = input.value;
  }

  initializeCard() {
    this.setTargetValue(this.cardNumberTarget, this.inputCardNumberTarget);
    this.setTargetValue(this.cardHolderNameTarget, this.inputCardHolderNameTarget);
    this.setTargetValue(this.cardExpDateTarget, this.inputCardExpDateTarget);
    this.setTargetValue(this.cardCvcTarget, this.inputCvcTarget);
  }

  setCardType() {
    this.cardTypeTargets.map((element) => { element.hidden = true; });
    if (this.inputCardNumberTarget.inputmask) {
      this.cardTypeTargets.forEach(element => {
        if (element.dataset.logo === detect(this.inputCardNumberTarget.inputmask.unmaskedvalue())) {
          element.hidden = false;
        }
      });
    }
  }

  typeFn(event, target, source) {
    if (event.currentTarget.value === '') {
      target.textContent = source;
    } else {
      target.textContent = event.currentTarget.value;
    }
  }

  typeOnCard(event) {
    switch (event.currentTarget.dataset.inputType) {
      case 'card-number':
        this.setCardType();
        this.typeFn(event, this.cardNumberTarget, this.cardNumber);
        break;
      case 'card-holder-name':
        this.typeFn(event, this.cardHolderNameTarget, this.cardHolderName);
        break;
      case 'card-expiration-date':
        this.typeFn(event, this.cardExpDateTarget, this.cardExpDate);
        break;
      case 'card-cvc':
        this.typeFn(event, this.cardCvcTarget, this.cardCvc);
        break;
      default:
        this.virtualCardWrapperTarget.classList.remove('is-flipped');
    }
    if (event.currentTarget.dataset.inputType === 'card-cvc') {
      this.virtualCardWrapperTarget.classList.add('is-flipped');
    } else {
      this.virtualCardWrapperTarget.classList.remove('is-flipped');
    }
  }

  paymentType(event) {
    event.preventDefault();
    const paymentType = event.currentTarget.dataset.paymentType;
    const currentPath = event.currentTarget.dataset.currentPath
    Rails.ajax({
      url: event.currentTarget.dataset.path,
      type: 'GET',
      data: 'payment_type=' + event.currentTarget.dataset.paymentType,
      accept: 'html',
      success: (response, status, xhr) => {
        this.paymentFormTarget.innerHTML = xhr.responseText;
        window.history.replaceState({}, "", currentPath + '&payment_type=' + paymentType);
      },
      complete:  (response, status, xhr) => {
        if(paymentType === 'credit_card')
          this.initialize();
      }
    });

    this.buttonTargets.forEach(element => element.classList.remove('bg-warning'));

    switch (event.currentTarget.dataset.paymentType) {
      case 'credit_card':
        event.currentTarget.classList.add('bg-warning');
        break;
      case 'transfer_or_eft':
        event.currentTarget.classList.add('bg-warning');
        break;
    }
  }
}