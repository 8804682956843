import { Controller } from 'stimulus';
import Player from '@vimeo/player'

export default class extends Controller {
    static targets=["player"]

    initialize(){
        if (this.data.get('available') == 'true' && this.data.get('lecture-type') == 'Video'){
            const player = new Player(this.playerTarget)
            player.on( "play",function (event){
                player.getPlayed().then(function(played) {
                    if (played.length == 0){
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'GAEvent', //Sabit
                            'event_name': 'video_play', //Sabit
                            'eventCategory': 'Video', //Sabit
                            'eventAction': 'Start', //Sabit
                            'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                            'breadcrumb': this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                            'videoCategory': this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                            'section': this.data.get('video-section'), //Video bölümü gönderilmelidir.
                            'step': this.data.get('video-step'), //Video adımı gönderilmelidir.
                        });

                    }
                }).catch(function(error) {
                    // an error occurred
                });
            })
            let firstProgress = true
            let secondProgress = true
            let thirdProgress = true
            let fourthProgress = true

            player.on( "timeupdate",function (event){
                localStorage.setItem(`${this.data.get('video-step')}currentTime` , event.seconds);
                let x = event.percent
                if (0.25 <= x && x < 0.5){
                    if (firstProgress){
                        firstProgress = false;
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'GAEvent', //Sabit
                            'event_name': 'video_25_play', //Sabit
                            'eventCategory': 'Video', //Sabit
                            'eventAction': '%25 Play', //Sabit
                            'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                            'breadcrumb': this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                            'videoCategory': this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                            'section': this.data.get('video-section'), //Video bölümü gönderilmelidir.
                            'step': this.data.get('video-step'), //Video adımı gönderilmelidir.
                        });

                    }
                }
                else if (0.5 <= x && x < 0.75){
                    if (secondProgress){
                        secondProgress =  false;
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'GAEvent', //Sabit
                            'event_name': 'video_50_play', //Sabit
                            'eventCategory': 'Video', //Sabit
                            'eventAction': '%50 Play', //Sabit
                            'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                            'breadcrumb': this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                            'videoCategory': this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                            'section': this.data.get('video-section'), //Video bölümü gönderilmelidir.
                            'step': this.data.get('video-step'), //Video adımı gönderilmelidir.
                        });


                    }
                }
                else if (0.75 <= x && x < 1){
                    if (thirdProgress){
                        thirdProgress = false;
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'GAEvent', //Sabit
                            'event_name': 'video_75_play', //Sabit
                            'eventCategory': 'Video', //Sabit
                            'eventAction': '%75 Play', //Sabit
                            'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                            'breadcrumb': this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                            'videoCategory': this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                            'section': this.data.get('video-section'), //Video bölümü gönderilmelidir.
                            'step': this.data.get('video-step'), //Video adımı gönderilmelidir.
                        });


                    }
                }
                else if ( x == 1){
                    if (fourthProgress){
                        fourthProgress = false;
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'GAEvent', //Sabit
                            'event_name': 'video_100_play', //Sabit
                            'eventCategory': 'Video', //Sabit
                            'eventAction': '%100 Play', //Sabit
                            'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                            'breadcrumb': this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                            'videoCategory': this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                            'section': this.data.get('video-section'), //Video bölümü gönderilmelidir.
                            'step': this.data.get('video-step'), //Video adımı gönderilmelidir.
                        });

                    }
                }
            })
        }
    }

    completeLesson(){
        if (this.data.get('available') == 'true' && this.data.get('lecture-type') == 'Video'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'GAEvent', //Sabit
                'event_name': 'video_dersi_tamamla', //Sabit
                'eventCategory': 'Video', //Sabit
                'eventAction': 'Dersi Tamamla', //Sabit
                'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                'breadcrumb': this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                'videoCategory': this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                'section': this.data.get('video-section'), //Video bölümü gönderilmelidir.
                'step': this.data.get('video-step'), //Video adımı gönderilmelidir.
            });
        }
    }

    prevLesson(){
        if (this.data.get('available') == 'true' && this.data.get('lecture-type') == 'Video'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'GAEvent', //Sabit
                'event_name': 'video_onceki_ders', //Sabit
                'eventCategory': 'Video', //Sabit
                'eventAction': 'Önceki Ders', //Sabit
                'eventLabel': this.data.get('label'), //Video ismi gönderilmelidir.
                'breadcrumb': this.data.get('prev-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                'videoCategory': this.data.get('prev-video-category'), //Video kategorisi gönderilmelidir.
                'section': this.data.get('prev-video-section'), //Video bölümü gönderilmelidir.
                'step': this.data.get('prev-video-step'), //Video adımı gönderilmelidir.
            });
        }
    }

    nextLesson(){
        if (this.data.get('available') == 'true' && this.data.get('lecture-type') == 'Video'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'GAEvent', //Sabit
                'event_name': 'video_onceki_ders', //Sabit
                'eventCategory': 'Video', //Sabit
                'eventAction': 'Önceki Ders', //Sabit
                'eventLabel': this.data.get('next-label'), //Video ismi gönderilmelidir.
                'breadcrumb':   this.data.get('next-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                'videoCategory': this.data.get('next-video-category'), //Video kategorisi gönderilmelidir.
                'section': this.data.get('next-video-section'), //Video bölümü gönderilmelidir.
                'step': this.data.get('next-video-step'), //Video adımı gönderilmelidir.
            });
        }
    }

    disconnect(){
        if (this.data.get('available') == 'true' && this.data.get('lecture-type') == 'Video'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'GAEvent', //Sabit
                'event_name': 'video_izleme_suresi', //Sabit
                'eventCategory': 'Video', //Sabit
                'eventAction': 'İzleme Süresi', //Sabit
                'eventLabel':this.data.get('label'), //Video ismi gönderilmelidir.
                'eventValue': localStorage.getItem(`${this.data.get('video-step')}currentTime`), //Video izleme süresi saniye cinsinden integer olarak gönderilmelidir.
                'breadcrumb':this.data.get('video-name-breadcrumb'), //Video ismi breadcrumb beraberinde gönderilmelidir.
                'videoCategory':this.data.get('video-category'), //Video kategorisi gönderilmelidir.
                'section':this.data.get('video-section'), //Video bölümü gönderilmelidir.
                'step':this.data.get('video-step'), //Video adımı gönderilmelidir.
            });
            localStorage.removeItem(`${this.data.get('video-step')}currentTime`);
        }
        }
}

