//------------------------------------
//	# Anchor component
//------------------------------------

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details"];

  connect() {
    this.openLastSection();
    this.addHighlightLastLecture();
  }
  openLastSection() {
    let sectionId = null
    if(window.location.pathname.includes("/sections/"))
      sectionId = window.location.pathname.split("/")[7]; // 7 is the index of the section id in the url

    this.detailsTargets.forEach((element, index) => {
      if (sectionId !== null && index === 0)
        element.open
      if (element.getAttribute("id") === `section_${sectionId}`) {
        element.open = true;
      }
    });
  }

  addHighlightLastLecture() {
    const lastLectureId = this.data.get("last-lecture-id");
    this.detailsTargets.forEach((element) => {
      element.querySelectorAll("a").forEach((link) => {
        if (link.getAttribute("href").includes(lastLectureId)){
          link.parentElement.style.backgroundColor = "#c8f4ca";
          const elementHeight = link.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
          const sectionArea = this.detailsTarget.parentElement.parentElement;
          sectionArea.scrollTop = elementHeight;
          link.scrollIntoView({behavior: "smooth", block: "center"});
        }
        else
          link.parentElement.style.backgroundColor = "#FAFAFA";
      });
    });
  }
}
