//------------------------------------
//	# Google Event
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['netPaidPrice'];

    connect(){
        if(this.data.get('page') === 'SuccessPage'){
            this.successTrackEvent(this.data.get('label'));
            this.successTrackEvent(this.data.get('secondLabel'));
        }
    }

    trackEvent(){
        if (this.data.get('available') == 'true'){
            gtag('event', this.data.get('action'), {
                'event_category': this.data.get('category'),
                'event_label': this.data.get('label'),
                'value': this.data.get('value')});
            console.log('running gtag method...')
        }
    }

    checkoutTrackEvent(event){
        if (this.data.get('available') == 'true'){
            event.currentTarget.dataset.googleEventValue = this.netPaidPriceTarget.dataset.paidPrice;
            gtag('event', this.data.get('action'), {
                'event_category': this.data.get('category'),
                'event_label': this.data.get('label'),
                'value': this.data.get('value')});
            console.log('running gtag method...')
        }
    }

    successTrackEvent(label) {
        if (this.data.get('available') == 'true'){
            gtag('event', this.data.get('action'), {
                'event_category': this.data.get('category'),
                'event_label': label,
                'value': this.data.get('value')});
            console.log('running gtag method...')
        }
    }
}
