//------------------------------------
//	# Facebook Pixel Code
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['netPaidPrice'];

    connect(){
        if(this.data.get('action') !== 'SignUp'){
            this.trackEvent();
        }
    }

    trackEvent(){
        if (this.data.get('available') === 'true'){
            fbq('track', this.data.get('action'), {
                content_type: 'product',
                content_ids: [this.data.get('content-ids')],
                content_name: this.data.get('content-name'),
                content_category: this.data.get('content-category'),
                value: this.data.get('value'),
                currency: 'TRY'
            });
        }
    }

    checkoutTrackEvent(event){
        if(event.currentTarget.dataset.facebookPixelCodeCheckoutAvailable === 'true'){
            event.currentTarget.dataset.facebookPixelCodeCheckoutValue = this.netPaidPriceTarget.dataset.paidPrice;
            fbq('track', event.currentTarget.dataset.facebookPixelCodeCheckoutAction, {
                content_type: 'product',
                content_ids: [event.currentTarget.dataset.facebookPixelCodeCheckoutContentIds],
                content_name: event.currentTarget.dataset.facebookPixelCodeCheckoutContentName,
                content_category: event.currentTarget.dataset.facebookPixelCodeCheckoutContentCategory,
                value: event.currentTarget.dataset.facebookPixelCodeCheckoutValue,
                currency: 'TRY'
            });
        }
    }

    signUpTrackEvent(){
        if(this.data.get('available') === 'true'){
            fbq('track', 'Lead', {
                value: 1.00,
                currency: 'TRY'
            });
        }
    }
}
