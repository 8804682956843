//------------------------------------
//	# Order component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ['form', 'userId', 'emailList', 'emailSearchField', 'emailSelectBox', 'fullName',
        'productId', 'productList', 'productSearchField', 'productSelectBox', 'price', 'discountPrice',
        'addressId', 'addressList', 'addressSearchField', 'addressFullName', 'mobilePhoneNumber', 'identityNumber', 'city', 'town', 'detailedAddress'];

    connect(){
        if(this.formTarget.dataset.errors === 'true'){
            if(this.userIdTarget.value !== ''){
                this.loadAddressRecordings();
            }
        }else{
            this.clearEmailFields();
            this.clearProductFields();
            this.clearAddressFields();
        }
    }

    loadEmailRecordings(event) {
        if(event.target.value.length > 3) {
            Rails.ajax({
                url: event.target.dataset.url + `/?q=${event.target.value}&email=true`,
                type: 'GET',
                accept: 'html',
                success: (response, status, xhr) => {
                    this.clearEmailFields();
                    this.clearAddressFields();
                    this.emailListTarget.innerHTML = xhr.response;
                }
            });
        }
    }

    openEmailList(event){
        this.loadEmailRecordings(event);
        this.emailListTarget.hidden = !this.emailListTarget.hidden;
    }

    selectEmailItem(event){
        this.emailListTarget.hidden = true;
        this.userIdTarget.value = event.currentTarget.value;
        this.emailSearchFieldTarget.value = event.currentTarget.innerText;
        this.fullNameTarget.value = event.currentTarget.dataset.fullName;
    }

    clearEmailFields(){
        this.userIdTarget.value = '';
        this.fullNameTarget.value = '';
    }


    loadProductRecordings(event) {
        Rails.ajax({
            url: event.target.dataset.url + `/?q=${event.target.value}&product=true`,
            type: 'GET',
            accept: 'html',
            success: (response, status, xhr) => {
                this.clearProductFields();
                this.productListTarget.innerHTML = xhr.response;
            }
        });
    }

    openProductList(event){
        this.loadProductRecordings(event);
        this.productListTarget.hidden = !this.productListTarget.hidden;
    }

    selectProductItem(event){
        this.productListTarget.hidden = true;
        this.productIdTarget.value = event.currentTarget.value;
        this.productSearchFieldTarget.value = event.currentTarget.innerText;
        this.priceTarget.value = event.currentTarget.dataset.price;
        this.discountPriceTarget.value = event.currentTarget.dataset.discountPrice;
    }

    clearProductFields(){
        this.productIdTarget.value = '';
        this.priceTarget.value = '';
        this.discountPriceTarget.value = '';
    }


    loadAddressRecordings(event) {
        let url = '';
        if(this.formTarget.dataset.errors === 'true') {
            url = 'orders/search_form' + `/?q=${this.userIdTarget.value}&address=true`;
        }else{
            url = 'search_form' + `/?q=${event.target.value}&address=true`;
        }

        Rails.ajax({
            url: url,
            type: 'GET',
            accept: 'html',
            success: (response, status, xhr) => {
                this.addressListTarget.innerHTML = xhr.response;
            }
        });
    }

    openAddressList(event){
        this.addressListTarget.hidden = !this.addressListTarget.hidden;
    }

    selectAddressItem(event){
        this.addressListTarget.hidden = true;
        this.addressIdTarget.value = event.currentTarget.value;
        this.addressSearchFieldTarget.value = event.currentTarget.innerText;
        this.addressFullNameTarget.value = event.currentTarget.dataset.fullName;
        this.mobilePhoneNumberTarget.value = event.currentTarget.dataset.mobilePhoneNumber;
        this.identityNumberTarget.value = event.currentTarget.dataset.identityNumber;
        this.cityTarget.value = event.currentTarget.dataset.city;
        this.townTarget.value = event.currentTarget.dataset.town;
        this.detailedAddressTarget.value = event.currentTarget.dataset.detailedAddress;
    }

    clearAddressFields(){
        this.addressIdTarget.value = '';
        this.addressSearchFieldTarget.value = '';
        this.addressListTarget.innerHTML = '';
        this.addressFullNameTarget.value = '';
        this.mobilePhoneNumberTarget.value = '';
        this.identityNumberTarget.value = '';
        this.cityTarget.value = '';
        this.townTarget.value = '';
        this.detailedAddressTarget.value = '';
    }

    send_orders_to_shipping(event){
        const order_ids = [];
        const checkboxes = document.querySelectorAll('input[type="checkbox"][name="order_ids[]"]');

        if(event.target.dataset.orderId != null)
            order_ids.push(event.target.dataset.orderId)

        checkboxes.forEach(function(checkbox) {
            if (checkbox.checked) {
              order_ids.push(checkbox.value);
            }
        });

        Rails.ajax({
            type: 'POST',
            url: event.target.dataset.path,
            data: `order_ids=${order_ids}`,
            success: function(response) {
            },
            error: function() {
            }
        });
    }
}