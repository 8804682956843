// jshint esversion:6

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

export const NativeEvaluateHandler = {
    // Data: { uuid: 'uuid', platform: 'platform', name: 'name', token: 'token' }
    saveDevice: (data) => {
        console.log("saveDevice")
        const formData = new FormData();
        buildFormData(formData, data);

        Rails.ajax({
            type: 'POST',
            url: '/user/devices',
            dataType: 'json',
            data: formData,
            success: (data) => {
                console.log("success data:", data)
            },
            error: (error) => {
                console.warn("error data:", error)
            }
        });
    },
    updateDevice: (data) => {
        console.log("updateDevice")
        const formData = new FormData();
        buildFormData(formData, data);

        Rails.ajax({
            type: 'PUT',
            url: '/user/devices/' + data["device"]["uuid"],
            dataType: 'json',
            data: formData,
            success: (data) => {
                console.log("success data:", data)
            },
            error: (error) => {
                console.warn("error data:", error)
            }
        });
    }
};

export default NativeEvaluateHandler;
