import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ["correct", "wrong", "total", "diploma"]

    scoreCalculation(){
        this.diplomaTarget.classList.remove("border-2")
        this.diplomaTarget.classList.remove("border-red-500")

        if( parseInt(this.diplomaTarget.value) > parseInt(this.diplomaTarget.max)){
            this.diplomaTarget.classList.add("border-2")
            this.diplomaTarget.classList.add("border-red-500")
        }

        const multipleTytArray = [3.3 , 3.4 , 3.3 , 3.4 ];
        const multipleDilArray = [1.32 , 1.32 , 1.36 , 1.36 , 3 ];
        var diploma = this.diplomaTarget.value == '' ? '0' : this.diplomaTarget.value
        var tytScore = 100 + parseInt(diploma) * 0.6
        var dilScore = 100 + parseInt(diploma) * 0.6

        for(var i=0; i<5; i++){
            this.correctTargets[i].classList.remove("border-2")
            this.correctTargets[i].classList.remove("border-red-500")
            this.wrongTargets[i].classList.remove("border-2")
            this.wrongTargets[i].classList.remove("border-red-500")

            var correct = parseInt(this.correctTargets[i].value == '' ? '0' : this.correctTargets[i].value)
            var wrong = parseInt(this.wrongTargets[i].value == '' ? '0' : this.wrongTargets[i].value)

            var validation = (correct + wrong) > parseInt(this.correctTargets[i].max) ? true : false
            if(validation){
                this.correctTargets[i].classList.add("border-2")
                this.correctTargets[i].classList.add("border-red-500")
                this.wrongTargets[i].classList.add("border-2")
                this.wrongTargets[i].classList.add("border-red-500")
            }

            var total = correct - wrong * 0.25

            this.totalTargets[i].value = validation ? 0 : total

            if (validation == false) {
                if (i != 4){
                    tytScore += total * multipleTytArray[i]
                }
                dilScore += total * multipleDilArray[i]
            }
        }
        this.totalTargets[5].value = tytScore.toFixed(3)
        this.totalTargets[6].value = dilScore.toFixed(3)
    }
}