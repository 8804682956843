import { Controller } from "stimulus";
import videojs from "video.js";

export default class extends Controller {
  connect() {
    this.initializePlayer = this.initializePlayer.bind(this);
    this.initializePlayer();
    document.addEventListener("turbolinks:load", this.initializePlayer);
    document.addEventListener("DOMContentLoaded", this.initializePlayer);
  }

  disconnect() {
    if (this.player) {
      this.player.dispose();
    }
    document.removeEventListener("turbolinks:load", this.initializePlayer);
  }

  initializePlayer() {
    const videoElement = this.element.querySelector("video");
    if (videoElement && videoElement.dataset.loaded !== "true") {
      if (this.player) {
        this.player.dispose();
      }
      this.player = videojs(videoElement, {
        controls: true,
        autoplay: false,
        preload: "auto",
        fluid: true,
        playsinline: true,
        playbackRates: [1, 1.25, 1.5, 1.75, 2],
        controlBar: {
          volumePanel: {
            inline: false,
            vertical: true
          },
          skipButtons: {
            forward: 10,
            backward: 10
          },
          pictureInPictureToggle: false,
          disablePictureInPicture: true,
          fullscreenToggle: true,
        },
        userActions: {
          doubleClick: this.doubleClickHandler,
          click: this.clickHandler
        }
      });
    }
    videoElement.dataset.loaded = "true";
  }

  clickHandler(event) {
    if (this.paused()) {
      this.play();
    }
    else {
      this.pause();
    }
  }

  doubleClickHandler(event) {
    if (this.isFullscreen()) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen();
    }
  }
}
