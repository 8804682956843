//------------------------------------
//	# Video component
//------------------------------------

// https://github.com/sampotts/plyr

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'recordings' ];

  connect() {
    console.log('lectures')
  }

  addLecture(event) {
    let [data, status, xhr] = event.detail;
    this.recordingsTarget.insertAdjacentHTML('beforeend',
        xhr.response
    );
  }

  reloadLectures() {
    let [data, status, xhr] = event.detail;
    this.recordingsTarget.innerHTML = xhr.response;
  }
}
