//------------------------------------
//	# Detect Credit Type
//------------------------------------

// jshint esversion: 6

export default function (cardNum) {

  let payCardType;
  const regexMap = [
    { regEx: /^4[0-9]{5}/ig, cardType: 'visa' },
    { regEx: /^5[1-5][0-9]{4}/ig, cardType: 'mastercard' },
    { regEx: /^3[47][0-9]{3}/ig, cardType: 'amex' },
    { regEx: /^(5[06-8]\d{4}|6\d{5})/ig, cardType: 'maestro' },
    { regEx: /^9[79][0-9]{3}/ig, cardType: 'troy' }
  ];

  for (const iterator of regexMap) {
    if (cardNum.match(iterator.regEx)) {
      payCardType = iterator.cardType;
      break;
    }
  }

  return payCardType;

}
