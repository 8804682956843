//------------------------------------
//	# Answer Autocomplete Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ['dropdown', 'textarea'];

  search(event) {
    this.sendSearchRequest(event);
  }

  insert(event) {
    this.textareaTarget.value = event.currentTarget.dataset.autocompleteValue;
    this.dropdownTarget.classList.remove('show');
  }

  close(){
    this.dropdownTarget.classList.remove('show');
  }

  sendSearchRequest(event) {
    if (event.target.value.length < 3) return;
    Rails.ajax({
      url: this.data.get('url') + `/?query=${(encodeURI(event.target.value))}`,
      type: 'GET',
      accept: 'JSON',
      success: (document, status, response) => {
        const list = JSON.parse(response.responseText);
        if (list.length > 0) {
          this.dropdownTarget.classList.add('show');
        } else {
          this.dropdownTarget.classList.remove('show');
        }
        const markup = `
          ${list.map(item => `<button type="button" data-action="click->admin--answer-autocomplete#insert" data-autocomplete-value="${item}" class="dropdown-item">
            ${item.length > 50 ? item.substring(0, 50) + '...' : item}
          </button>`).join('')}
          <div class="dropdown-divider"></div>
          <button type="button" data-action="click->admin--answer-autocomplete#close" class="btn btn-block btn-sm text-primary font-weight-bold">Kapat</button>
        `;
        this.dropdownTarget.innerHTML = markup;
      }
    });
  }

}
