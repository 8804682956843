//------------------------------------
//	# Alert component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['layout'];

  connect() {
    setTimeout(() => {
      this.layoutTarget.classList.add('slide-in');
    }, 100);
  }

  close() {
    this.layoutTarget.classList.remove('slide-in');
    this.layoutTarget.classList.add('slide-out');
    setTimeout(() => {
      document.body.removeChild(this.layoutTarget);
    }, 500);
  }

}

