//------------------------------------
//	# Product
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ['modal']

    connect() {
        this.modalTarget.classList.add('d-block');
        this.modalTarget.hidden = false;
        document.body.classList.add('modal-open');
    }
}
