//------------------------------------
//	# Registration Options Controller
//------------------------------------
// jshint esversion: 6
import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['displayOption', 'defaultOption']
  
  initialize() {
    this.defaultOptionTarget.click();
    this.registrationForm = null;
    this.sessionForm = null;
    this.emailForm = null;
  }
  loadRegistrationForm(event) {
    this.displayOptionTarget.innerHTML = '';
    document.querySelector('#registrationOptionForm').hidden = false;
    this.updateOptionDiv(event);
  }
  loadSessionForm(event) {
    document.querySelector('#registrationOptionForm').hidden = true;
    if(this.sessionForm == null) {
      const [data, status, xhr] = event.detail;
      const doc = new DOMParser().parseFromString(xhr.response, 'text/html');
      const images = doc.querySelectorAll('img');
      const registrationSection = doc.querySelector('section[aria-label="Sign-in form"]')

      if(images.length == 3)
        images[2].remove();
      if(registrationSection != null)
        registrationSection.parentElement.classList=''

      this.displayOptionTarget.innerHTML = doc.querySelector('html').innerHTML;
      this.updateOptionDiv(event);
    }
    else{
      this.displayOptionTarget.innerHTML = doc.querySelector('html').innerHTML;
      this.updateOptionDiv(event);
    }
  }
  loadEmailForm(event) {
    document.querySelector('#registrationOptionForm').hidden = true;
    if(this.emailForm == null) {
      const [data, status, xhr] = event.detail;
      const doc = new DOMParser().parseFromString(xhr.response, 'text/html');
      this.emailForm = doc;
      this.displayOptionTarget.innerHTML = this.emailForm.querySelector('html').innerHTML;
      this.updateOptionDiv(event);
    }
    else{
      this.displayOptionTarget.innerHTML = this.emailForm.querySelector('html').innerHTML;
      this.updateOptionDiv(event);
    }
  }
  updateOptionDiv(event) {
    event.currentTarget.style.color = '#ffffff';
    const optionsDiv = document.querySelector('div#options');
    optionsDiv.querySelectorAll('.option').forEach((option) => {
      if(option != event.currentTarget.parentElement) 
        option.querySelector('a').style.color = '#000000';
    });
  }
}