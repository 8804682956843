 //------------------------------------
//	# Exam Controller
//------------------------------------

// jshint esversion: 6

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['questionsContainer', 'questionBankContainer', 'questionBankSearch', 'btnSearch'];

  addQuestion(e) {
    this.submit(e,
      'POST',
      (document, status, response) => {
        this.questionsContainerTarget.insertAdjacentHTML('beforeend', response.responseText);
        this.refreshQuestionBank();
      },
      (document, status, response) => {
        this.container.insertAdjacentHTML('beforebegin', response.responseText);
        this.container.parentElement.removeChild(this.container);
      });
  }

  updateQuestion(e) {
    this.submit(e,
      'PATCH',
      (document, status, response) => {
        this.container.insertAdjacentHTML('beforebegin', response.responseText);
        this.container.parentElement.removeChild(this.container);
      },
      (document, status, response) => {
        this.container.insertAdjacentHTML('beforebegin', response.responseText);
        this.container.parentElement.removeChild(this.container);
      });
  }

  removeQuestion(e) {
    this.submit(e,
      'DELETE',
      (document, status, response) => {
        this.container.parentElement.removeChild(this.container);
        this.refreshQuestionBank();
      },
      (document, status, response) => {
        this.container.classList.remove('opacity');
        console.error(response);
      });
  }

  submit(e, ajax_type, success, error) {
    e.preventDefault();

    this.container = document.querySelector(`[data-question-dom-id="question_${e.target.dataset.questionId}"]`);
    this.container.classList.add('opacity');

    const formData = new FormData();
    formData.append('question_id', e.target.dataset.questionId);
    formData.append('exam_id', this.data.get('id'));
    formData.append('location', document.getElementById(`location_${e.target.dataset.questionId}`).value);

    const url = this.data.get('exam-question-path') + (ajax_type === 'POST' ? '' : `/${e.target.dataset.id}`);

    Rails.ajax({
      url,
      type: ajax_type,
      data: formData,
      accept: 'html',
      success,
      error
    });
  }

  refreshQuestionBank(e) {
    if (e) e.preventDefault();

    Rails.ajax({
      url: `${this.data.get('question-bank-path')}?search=${(encodeURI(this.questionBankSearchTarget.value))}`,
      type: 'GET',
      accept: 'html',
      success: (document, status, response) => {
        let html_response = response.responseText;
        /**
         * This replacement is needed because of will_paginate gem uses url_helper for detecting links.
         * While we are using a different path to just render this partial,
         * url_helper not match with our current page.
         */
        if (html_response.includes('admin/question_bank'))
          html_response = html_response.split('admin/question_bank').join('admin/exams');

        this.questionBankContainerTarget.innerHTML = html_response;
      },
      error: (document, status, response) => {
        console.error(response);
      }
    });
  }

  triggerSearchButton(e){
    if (e.keyCode == 13)
      this.btnSearchTarget.click();
  }
}
