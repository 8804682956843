//------------------------------------
//	# Google Event
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if(this.data.get('page') === 'SuccessPage'){
      this.trackPurchaseEvent();
    }
  }

  trackRegisterEvent(){
    this.trackEvent(this.data.get('register-category'), this.data.get('register-action'), this.data.get('register-label') )
  }

  trackLoginEvent(){
    this.trackEvent(this.data.get('login-category'), this.data.get('login-action'), this.data.get('login-label') )
  }

  trackPurchaseEvent() {
    if (this.data.get('available') == 'true'){
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'ecPurchase',
        'eventCategory': 'Enhanced Ecommerce', //Sabit
        'eventAction': 'Purchase',
        'eventLabel': this.data.get('purchase-label'), //Sipariş idsi gönderilmelidir.
        'eventValue': this.data.get('purchase-value'), //Toplam ödenen tutar gönderilmelidir.
        'totalPriceDiscount': this.data.get('purchase-discount'), //Totalde sipariş beraberinde ne kadar indirim yapıldıysa o gönderilmelidir.
        'promotionCodeStatus': this.data.get('purchase-promotion-code-status'),  // Promosyon kullanıp kullanılmadığı gönderilmelidir.
        'paymentMethod': this.data.get('purchase-payment-method'),  // Ödeme yöntemi gönderilmelidir.
        'taksit': this.data.get('purchase-installment'),  // Taksit tutarı gönderilmelidir.
        'ecommerce': {
          'currencyCode': 'TRY', // Para birimi
          'purchase': {
            'actionField': {
              'id': this.data.get('purchase-order-id'), // Sipariş idsi
              'revenue': this.data.get('purchase-revenue'), // Toplam sepet tutarı gönderilmelidir.
              'coupon': this.data.get('purchase-coupon-title') //Kupon/promosyon kodu kullanıldıysa ismi gönderilecektir.
            },
            'products': [{
              'name': this.data.get('purchase-product-name'), //Paketin ismi.
              'id': this.data.get('purchase-product-id'), //Paketin unique idsi.
              'price': this.data.get('purchase-price'), //Paketin fiyatı
              'category': 'YDT', //Sabit
              'variant': this.data.get('purchase-variant'), //Paketin kullanılabileceği maksimum süre.
              'quantity': '1', //Sabit
              'list': 'YDS/YÖKDİL', //Sabit
              'position': this.data.get('purchase-position') , //Paketin görüntülendiği liste içerisindeki pozisyon bilgisi gönderilmelidir.
            }]
          }}
      });
    }
  }


  trackEvent(category, action, label) {
    if (this.data.get('available') == 'true'){
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'GAEvent',
        'eventCategory': category, //Sabit
        'eventAction': action, //Sabit
        'eventLabel': label //Sabit
      });
    }
  }
}
