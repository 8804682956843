//------------------------------------
//	# Alert component
//------------------------------------

// jshint esversion: 6

import { Controller } from 'stimulus';
import {isInteger} from "@vimeo/player/src/lib/functions";

export default class extends Controller {

    static targets = [ "menu","accordionTag", "accordionIcon", "score" , "count", "imgBig", "smallImg", "slide"];


    initialize() {
        this.index = 0
        this.showCurrentSlide()
    }

    connect() {
        this.refreshTimer = setInterval(() => {
                this.next()
                }, 4000)
             }

    disconnect() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
            }
        }

   next() {
        this.index++
        this.index = this.index % this.slideTargets.length
        this.showCurrentSlide()
    }

    previous() {
        this.index--
        if (this.index < 0 ) {
            this.index = this.slideTargets.length-1
        }
        this.index = this.index % this.slideTargets.length
            this.showCurrentSlide()
            }

    showCurrentSlide() {
        this.slideTargets.forEach((element, index) => {
            element.hidden = index != this.index
                })
        }


    openMobilMenu() {
        const menu = this.menuTarget;
        menu.classList.toggle("hidden")
    }

    closeMenu() {
        const menu = this.menuTarget;
        menu.classList.toggle("hidden")
    }

    accordionToggle(event)
    {

        this.accordionTagTargets.map((item, key) => {
            if (item.dataset.accordionId === event.currentTarget.dataset.accordionId) {
                this.closeToggle(key);
                if(item.classList.contains("hidden") === false) {
                    this.closeToggle(key);
                }
                item.classList.toggle("hidden")
                if(item.classList.contains("hidden") === true) {

                    this.openToggle(key);
                }
            }else {
                item.classList.add("hidden")
                this.openToggle(key);
            }
        });
    }

    closeToggle(key) {
        this.accordionIconTargets[key].innerHTML = "";
        this.accordionIconTargets[key].insertAdjacentHTML(
            "afterbegin",
            this.templateIconClose()
        );
    }

    openToggle(key) {

        this.accordionIconTargets[key].innerHTML = "";
        this.accordionIconTargets[key].insertAdjacentHTML(
            "afterbegin",
            this.templateIconOpen()
        );
    }

    templateIconClose() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg"   fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16" >
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>       `;
    }

    templateIconOpen() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-down " viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>      `;
    }


    score_calc(){
        const element = this.scoreTarget
        const score = element.value
        const count = score / 1.25;

        if (score <= 0){
            this.countTarget.innerText = "Lütfen geçerli bir değer giriniz."
        }
        else
        {
            this.countTarget.innerText = `${score} puan almanız için ${count} doğru yapmalısınız.`
        }

    }

    imgGallery(event){
            const imgBig = this.imgBigTarget;
            const smallImg =  event.currentTarget.getAttribute("src");
            imgBig.src = smallImg;
    }





}

