
import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
    initialize(){
        if (this.data.get('page') == 'CheckoutPage'){
            this.checkoutPage();
        }
    }

    checkoutPage(){
        if (this.data.get('available') == 'true'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'ecCheckout', //Sabit
                'eventCategory': 'Enhanced Ecommerce', //Sabit
                'eventAction': 'Checkout', //Sabit
                'eventLabel': this.data.get('label'), //Paket ismi
                'ecommerce': {
                    'currencyCode': 'TRY', //Currency code
                    'checkout': {
                        'actionField': {'step': 1, 'list': 'YDS/YÖKDİL'}, //Sabit
                        'products': [{
                            'name': this.data.get('products-name'), //Paketin ismi.
                            'id': this.data.get('products-id'), //Paketin unique idsi.
                            'price': this.data.get('products-price'), //Paketin fiyatı
                            'category': 'YDT', //Sabit
                            'variant': this.data.get('products-variant'), //Paketin kullanılabileceği maksimum süre.
                            'quantity': '1', //Sabit
                            'list': 'YDS/YÖKDİL', //Sabit
                            'position': this.data.get('products-position'), //Paketin görüntülendiği liste içerisindeki pozisyon bilgisi gönderilmelidir.
                        }]
                    }
                }
            });
        }
    }

    paymentEvent(event){
        if (this.data.get('available') == 'true'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'ecCheckout', //Sabit
                'eventCategory': 'Enhanced Ecommerce', //Sabit
                'eventAction': 'Checkout', //Sabit
                'eventLabel': this.data.get('label'), //Paket ismi
                'ecommerce': {
                    'currencyCode': 'TRY', //Currency code
                    'checkout': {
                        'actionField': {'step': 1, 'option': event.currentTarget.dataset.paymentType, 'list': 'YDS/YÖKDİL'}, //Sadece option kısmı güncellenmelidir.
                        'products': [{
                            'name': this.data.get('products-name'), //Paketin ismi.
                            'id': this.data.get('products-id'), //Paketin unique idsi.
                            'price': this.data.get('products-price'), //Paketin fiyatı
                            'category': 'YDT', //Sabit
                            'variant': this.data.get('products-variant'), //Paketin kullanılabileceği maksimum süre.
                            'quantity': '1', //Sabit
                            'list': 'YDS/YÖKDİL', //Sabit
                            'position': this.data.get('products-position'), //Paketin görüntülendiği liste içerisindeki pozisyon bilgisi gönderilmelidir.
                        }]
                    }
                }
            });
        }
    }


}